const config = {
    host: (process.env.NODE_ENV === 'production') ? 'http://mitaxiplus.dvgeo.app' : 'http://192.168.1.106:3023',
    homePage: (process.env.NODE_ENV === 'production') ? 'http://mitaxiplus.dvgeo.app' : 'http://192.168.1.106:3023',
    api: 'api/',
    apiSocket: 'mitaxiplus::1.2',
    nameDir: 'mitaxiplus',
    package: 'app.dvgeo.mitaxiplus.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyDfEjU7BK0I_c8rMFrzUe-ezeX5lIMmC8w',
    appName: 'MiTaxiPlus',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#D8B900',
    colorDark: '#219821',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.mitaxiplus.passenger',
    playStoreDriverId: 'app.dvgeo.mitaxiplus.driver',
    appStorePassengerId: '6458537194',
    appStoreDriverId: '6458537034',
    email: "byronmova@gmail.com",
};
export default config;
